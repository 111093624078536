import { createRouter, createWebHistory } from 'vue-router'
import { getCookie, setCookie, deleteAllCookies, deleteCookie } from '@/utilities/cookieTools'
import { getUser } from '@/utilities/accessControl'
import axios from '@/utilities/axios'

// Default routes
const defaultChildRoutes = (prefix) => [
  // Users Pages
  {
    path: '/user',
    name: prefix + '.user-list',
    meta: {
      auth: true,
      name: 'Listagem de usuários',
      isBanner: true,
      role: 'AccessManagement',
      permission: 'index'
    },
    component: () => import('@/views/user/ListUserPage.vue')
  },
  {
    path: '/group',
    name: prefix + '.group-list',
    meta: {
      auth: true,
      name: 'Listagem de Grupos',
      isBanner: true,
      role: 'AccessManagement',
      permission: 'index'
    },
    component: () => import('@/views/user/ListPage.vue')
  },
  {
    path: '/user/:id',
    name: prefix + '.user-profile',
    meta: {
      auth: true,
      name: 'Perfil do Usuário',
      isBanner: true,
      role: 'AccessManagement',
      permission: 'show'
    },
    props: true,
    component: () => import('@/views/user/ProfilePage.vue')
  },
  {
    path: '/admin-permissions/:id',
    name: prefix + '.admin-permissions',
    meta: {
      auth: true,
      name: 'Gestão de Acesso',
      isBanner: true,
      role: 'AccessManagement',
      permission: 'show'
    },
    props: true,
    component: () => import('@/views/admin/AdminPage.vue')
  }
]
const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: '404',
    name: prefix + '.404',
    meta: { auth: true, name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  },
  {
    path: '500',
    name: prefix + '.500',
    meta: { auth: true, name: 'Error 500', isBanner: true },
    component: () => import('@/views/errors/Error500Page.vue')
  },
  {
    path: 'maintenance',
    name: prefix + '.maintenance',
    meta: { auth: true, name: 'Maintenance', isBanner: true },
    component: () => import('@/views/errors/MaintenancePage.vue')
  }
]
const routes = [
  {
    path: '/',
    redirect: '/user' // Redireciona a rota raiz para /user
  },
  {
    path: '/',
    name: 'user',
    meta: {
      auth: true,
      role: 'AccessManagement',
      permission: 'index'
    },
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('user')
  },

  // Errors Pages
  {
    path: '/errors',
    name: 'errors',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: errorRoutes('errors')
  },

  {
    path: '/:pathMatch(.)',
    name: '.404',
    meta: { auth: true, name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  }
]
const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

const savePermissionsToCookie = (data) => {
  const accessControlList = {}
  // Iterar sobre cada conta
  for (const account of data.user.accounts) {
    const accountId = account.id
    accessControlList[accountId] = []
    // Iterar sobre cada grupo de gestão
    for (const groupName in data.gestaoGrupos) {
      const grupo = data.gestaoGrupos[groupName]
      // Filtrar itens do grupo que pertencem à conta atual
      const accountItems = grupo.filter((item) => item.account.id === accountId)
      // Verificar se há itens para a conta atual no grupo
      if (accountItems.length > 0) {
        // Agrupar permissões pelo nome da função
        const permissionsByRoleName = {}
        for (const item of accountItems) {
          const roleName = item.role.name
          const permissionName = item.permission.name
          if (!permissionsByRoleName[roleName]) {
            permissionsByRoleName[roleName] = []
          }
          permissionsByRoleName[roleName].push({
            permission_name: permissionName,
            contracts: [
              {
                contract_id: item.contract_id,
                unity_id: item.unity_id,
                company_id: item.company_id
              }
            ]
          })
        }
        // Adicionar permissões agrupadas à lista de controle de acesso
        accessControlList[accountId].push(permissionsByRoleName)
      }
    }
  }
  // Criar um novo objeto sem objetos vazios
  const filteredAccessControlList = {}
  for (const key in accessControlList) {
    if (accessControlList[key].length > 0) {
      filteredAccessControlList[key] = accessControlList[key]
    }
  }
  // Salvar dados no cookie apenas se houver informações válidas
  if (Object.keys(filteredAccessControlList).length > 0) {
    setCookie('permissions', JSON.stringify(filteredAccessControlList), 1)
  }
}

router.beforeEach(async (to, from, next) => {
  // Página não encontrada
  if (!router.resolve(to.path).matched.length) {
    return next({ name: 'errors.404' })
  }

  const token = getCookie('token')
  const account = JSON.parse(getCookie('account_id'))

  try {
    // Redireciona para a página de login se autenticação é necessária e não há token
    if (to.meta.auth && !token || !account) {
      window.location.href = `${process.env.VUE_APP_DIRECT}?redirect=admin`
      deleteAllCookies()
      return;
    }

    axios.get(`/api/admin/user/${getUser.id}`).then(({ data }) => {
      deleteCookie('permissions')
      
      //Dinâmica para a role MASTER
      const isMaster = data.user.roles.some((role) => role.name === 'Master');
      if (!isMaster) {
        savePermissionsToCookie(data) // salva as permissões no cookies.
      } else {
        setCookie('d98b190ec15452a2e7d9f07f0495a676', isMaster, 1) // Define um cookie indicando permissão master
      }
    })

    next() // Continua a navegação normalmente
  } catch (error) {
    console.error('Erro na verificação do token:', error)
    // Em caso de erro, redireciona para a página de erro
    next({ name: 'errors.404' })
  }
})

export default router
