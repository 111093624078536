import { getUser } from '@/utilities/accessControl'
import axios from '@/utilities/axios'
import { getCookie, deleteAllCookies } from '@/utilities/cookieTools'
import { editIcon, removeIcon, addIcon } from '@/utilities/icons'

export default {
  namespaced: true,
  state: {
    groupData: {
      id: '',
      name: '',
      active: ''
    },
    userData: {
      id: '',
      name: '',
      email: ''
    },
    listaGrupos: [],
    listaUsuarios: [],
    gruposPermissoes: []
  },
  mutations: {
    // TODO: Operações de Grupos de Acesso
    VIEW_GROUP(state, data) {
      state.groupData = data
    },
    LISTAR_GRUPOS(state, data) {
      const all = data.management.group_permission
      const listaGrupo = []
      const listaPermissao = []
      all.forEach((item) => {
        if (item.gestao_grupo.length > 0) {
          const grupoAcesso = item.gestao_grupo
          grupoAcesso.forEach((subItem) => {
            let rowData = {
              id: subItem.id,
              name: subItem.name,
              action: {
                status: subItem.active,
                color: subItem.active ? 'bg-success' : 'bg-warning'
              },
              iconAction: {
                iconedit: editIcon,
                iconadd: addIcon,
                iconremove: removeIcon
              }
            }
            listaGrupo.push(rowData)

            let permissionData = {
              value: subItem.id,
              text: subItem.name
            }
            listaPermissao.push(permissionData)
          })
        }
      })
      state.listaGrupos = listaGrupo
      state.gruposPermissoes = listaPermissao
    },
    ATUALIZAR_GRUPO(state, { index, grupo }) {
      state.listaGrupos.splice(index, 1, grupo)
    },
    REMOVER_GRUPO(state, groupId) {
      const index = state.listaGrupos.findIndex((g) => g.id === groupId)
      if (index !== -1) {
        return state.listaGrupos.splice(index, 1)
      }
    },
    ADICIONAR_GRUPO(state, data) {
      state.listaGrupos.push(data)
    },

    // TODO: Operações de Usuários
    ADICIONAR_USUARIO(state, data) {
      state.listaUsuarios.push(data)
    },
    ATUALIZAR_USUARIO(state, { index, user }) {
      state.listaUsuarios.splice(index, 1, user)
    },
    VIEW_USER(state, data) {
      state.userData = data
    },
    LISTAR_USUARIOS(state, data) {
      if (data !== '') {
        const all = data.users
        const usersList = []
        all.forEach((item) => {
          let rowData = {
            id: item.id,
            name: item.name,
            email: item.email,
            iconAction: {
              icondetails: addIcon,
              iconedit: editIcon,
              iconremove: removeIcon
            }
          }
          usersList.push(rowData)
        })
        return (state.listaUsuarios = usersList)
      }
      return state.listaUsuarios = [];
    },
    DELETAR_USUARIO(state, userId) {
      const index = state.listaUsuarios.findIndex((u) => u.id === userId)
      if (index !== -1) {
        return state.listaUsuarios.splice(index, 1)
      }
    }
  },
  actions: {
    // TODO: Operações de Grupos de Acesso
    async BUSCAR_GRUPOS({ commit, rootState }) {
      try {
        const account_id = rootState.filter.account_id || getUser.account_id[0].id
        const response = await axios.get('/api/admin/gestao-grupo', {
          headers: {
            'account-id': account_id
          }
        })
        if (response.status === 200) {
          commit('LISTAR_GRUPOS', response.data)
        }
      } catch (error) {
        if (error.response.data.message.toLowerCase() == 'unauthenticated.') {
          deleteAllCookies()
          window.location.href = process.env.VUE_APP_DIRECT
        }
      }
    },
    async EDITAR_GRUPO({ commit, state }) {
      try {
        await axios
          .put('/api/admin/gestao-grupo', {
            id: state.groupData.id,
            name: state.groupData.name,
            active: state.groupData.active
          })
          .then(({ data }) => {
            const index = state.listaGrupos.findIndex((g) => g.id === state.groupData.id)
            if (index !== -1) {
              commit('ATUALIZAR_GRUPO', {
                index,
                grupo: {
                  id: state.groupData.id,
                  name: state.groupData.name,
                  action: {
                    status: state.groupData.active,
                    color: state.groupData.active ? 'bg-success' : 'bg-warning'
                  },
                  iconAction: {
                    iconedit: editIcon,
                    iconadd: addIcon,
                    iconremove: removeIcon
                  }
                }
              })
            } else {
              console.error('Erro na edição do grupo', response.data)
            }
          })
      } catch (error) {
        if (typeof error.response.data.message != null) {
          throw new Error(error.response.data.message.toLowerCase())
        } else if (error.response.data.message.toLowerCase() == 'unauthenticated.') {
          throw new Error('unauthenticated.')
        }
      }
    },
    async DELETAR_GRUPO({ commit, state }) {
      try {
        await axios.delete(`/api/admin/gestao-grupo/${state.groupData.id}`).then(({ data }) => {
          if (data.status == 'success') {
            commit('REMOVER_GRUPO', state.groupData.id)
          }
        })
      } catch (error) {
        if (typeof error.response.data.message != null) {
          throw new Error(error.response.data.message.toLowerCase())
        } else if (error.response.data.message.toLowerCase() == 'unauthenticated.') {
          throw new Error('unauthenticated.')
        }
      }
    },
    async ADICIONAR_GRUPO({ commit, rootState }, newGroup) {
      const user_id = getUser.id
      const account_id = rootState.filter.account_id
      const session_id = getCookie('session_id')

      try {
        const novoGrupo = await axios.post('/api/admin/gestao-grupo', {
          name: newGroup,
          active: true,
          grupo_id: account_id,
          session_history_id: session_id
        })
        const grupoCriadoId = novoGrupo.data.gestaoGrupo.id
        const assign = await axios
          .post('/api/admin/gestao-grupo/assign', {
            user_id,
            grupo_id: account_id,
            funcao_grupo_id: grupoCriadoId,
            session_history_id: 140
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('ADICIONAR_GRUPO', {
                id: novoGrupo.data.gestaoGrupo.id,
                name: novoGrupo.data.gestaoGrupo.name,
                action: {
                  status: novoGrupo.data.gestaoGrupo.active,
                  color: novoGrupo.data.gestaoGrupo.active ? 'bg-success' : 'bg-warning'
                },
                iconAction: {
                  iconedit: editIcon,
                  iconadd: addIcon,
                  iconremove: removeIcon
                }
              })
            }
          })
      } catch (error) {
        if (typeof error.response.data.message != null) {
          throw new Error(error.response.data.message.toLowerCase())
        } else if (error.response.data.message.toLowerCase() == 'unauthenticated.') {
          throw new Error('unauthenticated.')
        }
      }
    },

    // TODO: Operações de Usuários
    async ADICIONAR_USUARIO({ commit }, newUser) {
      try {
        await axios
          .post('/api/admin/user', {
            name: newUser.name,
            email: newUser.email,
            group_id: newUser.group_id
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('ADICIONAR_USUARIO', {
                id: data.users.id,
                name: data.users.name,
                email: data.users.email,
                iconAction: {
                  icondetails: addIcon,
                  iconedit: editIcon,
                  iconremove: removeIcon
                }
              })
            }
          })
      } catch (error) {
        if (typeof error.response.data.message != null) {
          throw new Error(error.response.data.message.toLowerCase())
        } else if (error.response.data.message.toLowerCase() == 'unauthenticated.') {
          throw new Error('unauthenticated.')
        }
      }
    },
    async BUSCAR_USUARIOS({ commit }, dados) {
      let url = 'api/admin/user'
      let config = {}

      // Configuração condicional baseada na presença de 'account', 'contract', 'unity', 'company'
      if (dados?.account) {
        config.headers = { 'account-id': dados?.account }
      } else if (dados?.contract) {
        url = `api/admin/user/contrato/${dados?.contract}`
      } else if (dados?.unity) {
        url = `api/admin/user/contrato/${dados?.unity[0]}/unidade/${dados?.unity[1]}`
      } else if (dados?.company) {
        url = `api/admin/user/contrato/${dados?.company[0]}/unidade/${dados?.company[1]}/empresa/${dados?.company[2]}`
      }

      // Realizando a requisição com a URL e configurações definidas
      try {
        const response = await axios.get(url, config)
        const data = response.data;
        if (response.status === 200 && data.status === 'success') {
          commit('LISTAR_USUARIOS', response.data)
        } else (
          commit('LISTAR_USUARIOS', '')
        )
      } catch (error) {
        if (error.response && error.response.data.message.toLowerCase() === 'unauthenticated.') {
          deleteAllCookies()
          window.location.href = process.env.VUE_APP_DIRECT
        }
      }
    },
    async DELETAR_USUARIO({ commit, state }) {
      try {
        await axios.delete(`/api/admin/user/${state.userData.id}`).then(( data ) => {
          if (data.status === 200) {
            commit('DELETAR_USUARIO', state.userData.id)
          }
        })
      } catch (error) {
        if (typeof error.response.data.message != null) {
          throw new Error(error.response.data.message.toLowerCase())
        } else if (error.response.data.message.toLowerCase() == 'unauthenticated.') {
          throw new Error('unauthenticated.')
        }
      }
    },
    async EDITAR_USUARIO({ commit, state }, data) {
      try {
        await axios
          .put(`/api/admin/user/${data.id}`, {
            name: data.name,
            email: data.email
          })
          .then(({ data }) => {
            const index = state.listaUsuarios.findIndex((u) => u.id === state.userData.id)
            if (index !== -1) {
              commit('ATUALIZAR_USUARIO', {
                index,
                user: {
                  id: data.users.id,
                  name: data.users.name,
                  email: data.users.email,
                  iconAction: {
                    icondetails: addIcon,
                    iconedit: editIcon,
                    iconremove: removeIcon
                  }
                }
              })
            }
          })
      } catch (error) {
        if (typeof error.response.data.message != null) {
          throw new Error(error.response.data.message.toLowerCase())
        } else if (error.response.data.message.toLowerCase() == 'unauthenticated.') {
          throw new Error('unauthenticated.')
        }
      }
    }
  }
}
