import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'

// Library Components
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue3-apexcharts'
import BootstrapVue3 from 'bootstrap-vue-3'
import CounterUp from 'vue3-autocounter'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
require('waypoints/lib/noframework.waypoints.min')

// Connect API
import VueAxios from 'vue-axios'
import axios from './utilities/axios'
import emitter from './utilities/emit'
import { getUser, findPermissions } from './utilities/accessControl'

const app = createApp(App)
app.use(store).use(router).use(i18n)

// ACCESS CONTROL
app.provide('user', getUser );
app.provide('findPermissions', findPermissions);

// Axios Connection
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

// Library Components
app.use(VueSweetalert2);
window.Swal =  app.config.globalProperties.$swal;
app.use(VueApexCharts);
app.use(BootstrapVue3);
app.component('counter-up', CounterUp);

const destroy = async function (id, name) {
  emitter.emit('eventDestroy', { id: id, name: name});
}
window.destroy = destroy;

//? DELETAR RELACIONAMENTO USER/FUNÇÃO GRUPO.
const deletePermission = async function (id, name, contract, unity, company) {
  emitter.emit('permissionDestroy', {id: id, name: name, contract: contract, unity: unity, company: company})
}
window.deletePermission = deletePermission;





// Custom Components & Directives
app.use(globalComponent);
app.use(globalDirective);
app.mixin(globalMixin);


app.mount('#app')

export default app
