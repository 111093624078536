import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import setting from './setting'
import admin from './admin'
import filter from './filter'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state: {
    lang: 'pt-BR',
    loader: false
  },
  getters: {
    lang: (state) => state.lang
  },
  mutations: {
    langChangeCommit(state, payload) {
      state.lang = payload
    },
    openModalCommit(state, payload) {
      state[payload.name] = payload.value
    },
    openBottomCanvasCommit(state, payload) {
      state[payload.name] = payload.value
    },
    changeLoader(state, data) {
      state.loader = data
    }
  },
  actions: {
    openModalAction({ commit }, payload) {
      commit('openModalCommit', payload)
    },
    openBottomCanvasAction({ commit }, payload) {
      commit('openBottomCanvasCommit', payload)
    },
    langChangeAction(context, payload) {
      context.commit('langChangeCommit', payload)
    }
  },
  modules: {
    setting: setting,
    admin,
    filter
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  plugins: [createPersistedState({
    key: 'adapts-filters',
    paths: ['filter'] // Aqui você pode especificar quais módulos deseja persistir
  })]
})
